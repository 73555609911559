import { css } from 'lit';
export const style = css`
  :host {
    cursor: text;
  }

  .one-ux-element--root {
    --one-ux-textarea-element--line-height: var(--one-ux-line-height--body-300);

    font: var(--one-ux-font--body-300);
    letter-spacing: var(--one-ux-letter-spacing--body-300);

    box-sizing: border-box;
    padding: var(--one-ux-spacing--small) 0;
  }

  [one-ux-element='one-ux-editable-text'] {
    padding-left: var(--one-ux-spacing--300);
    padding-right: var(--one-ux-spacing--400);

    min-width: 140px;
    max-width: 700px;

    &[disabled] {
      color: var(--one-ux-palette--gray-400);
    }
  }
`;