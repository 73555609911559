import { html, PropertyValues } from 'lit';
import { property, state } from 'lit/decorators.js';
import { StyledFactory } from '../../mixins/Styled.js';
import { OneUxElement } from '../../OneUxElement.js';
import { style } from './style.js';
import { type IValue, ValueFactory } from '../../mixins/Value.js';
import { type isoDateString, isoDate } from './IsoDate.js';
import { Optional } from '../../types.js';
import { consume } from '@lit/context';
import { defaultDropdownContext, dropdownContext } from '../../contexts/DropdownContext.js';
import { DatePicker } from './fragments/DatePicker.js';
import { defaultPopoutContext, popoutContext } from '../../contexts/PopoutContext.js';
import { Lang } from '../../visualizations/common/mixins/Lang.js';
import { Focusable } from '../../mixins/Focusable.js';
import { type IRequired, Required } from '../../mixins/Required.js';
import { FormAssociated } from '../../mixins/FormAssociated.js';
import { getFormValidationLanguage, ValidatedFactory, validResult } from '../../mixins/Validated.js';
import { DropdownPreview } from './fragments/DropdownPreview.js';
import { defaultLabelContext, labelContext } from '../../contexts/LabelContext.js';
import { Disabled } from '../../mixins/Disabled.js';
import { getLanguage } from './language.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { register as _registerElement } from "../one-ux-button/register-element.js";
_registerElement("button-4af9c8ad5eff83098c1a5f4df9a6297c");
const Styled = StyledFactory(style);
type valueType = [] | [string] | [string, string];
const Value = ValueFactory<valueType>({
  type: Array,
  defaultValue() {
    return [];
  },
  isEmpty(value) {
    if (Array.isArray(value)) {
      return !value.length;
    }
    return true;
  }
});
const Validated = ValidatedFactory<IValue<valueType> & IRequired>({
  validator() {
    if (!this.required) {
      return validResult;
    }
    const {
      fieldIsRequired
    } = getFormValidationLanguage(this);
    const valid = !this.empty;
    return {
      valid,
      flags: {
        valueMissing: !valid
      },
      errors: [fieldIsRequired]
    };
  }
});
const BaseClass = FormAssociated(Validated(Disabled(Required(Focusable(Lang(Value(Styled(OneUxElement))))))));
export class OneUxDatePickerElement extends BaseClass {
  static get elementType() {
    return 'one-ux-date-picker';
  }
  @property({
    type: Boolean
  })
  public accessor range = false;
  #typedValue: [] | [isoDateString] | [isoDateString, isoDateString] = [];
  #min: Optional<isoDateString>;
  @property()
  public set min(min: Optional<string>) {
    this.#min = isoDate.tryParse(min);
  }
  public get min() {
    return this.#min;
  }
  #max: Optional<isoDateString>;
  @property()
  public set max(max: Optional<string>) {
    this.#max = isoDate.tryParse(max);
  }
  public get max() {
    return this.#max;
  }
  #at: Optional<isoDateString>;
  @property()
  public set at(at: Optional<string>) {
    this.#at = isoDate.tryParse(at);
  }
  public get at() {
    return this.#at;
  }
  @state()
  protected accessor _pendingUnsortedRangeValue: [isoDateString, isoDateString] | null = null;
  @state()
  protected accessor _activeDates!: [isoDateString, isoDateString];
  @state()
  protected accessor _previewHasFocus = false;
  @consume({
    context: dropdownContext,
    subscribe: true
  })
  protected _dropdownContext = defaultDropdownContext;
  @consume({
    context: popoutContext,
    subscribe: true
  })
  protected _popoutContext = defaultPopoutContext;
  @consume({
    context: labelContext,
    subscribe: true
  })
  protected _labelContext = defaultLabelContext;
  constructor() {
    super();
    this.addEventListener('blur', () => {
      this._clearPendingRange();
    });
  }
  protected willUpdate(changed: PropertyValues<this>): void {
    if (changed.has('range') && !changed.has('value') && this.value?.length) {
      this.value = this.range ? [this.value[0], this.value[1] ?? this.value[0]] : [this.value[0]];
    }
    if (changed.has('value') || changed.has('range')) {
      this.#checkAndEnsureValue();
    }
    const emptyStartReferenceChanged = changed.has('at') && this.empty;
    if (!this.hasUpdated || changed.has('value') || emptyStartReferenceChanged) {
      this.#resetActiveDates();
    }
    this._dropdownContext.updatePreview(DropdownPreview.call(this, {
      value: this.#typedValue,
      min: this.#min,
      max: this.#max
    }), {
      interactive: true,
      icon: {
        set: 'default',
        icon: 'date'
      }
    });
  }
  render() {
    const {
      translations,
      lang
    } = getLanguage(this);
    return html`
      <div
        class="one-ux-element--root"
        role="group"
        aria-roledescription=${translations.datePicker}
        aria-label=${ifDefined(this._labelContext.label ? this._labelContext.label : undefined)}
        aria-disabled=${this.disabled}
        lang=${lang}
        tabindex="-1"
        @focus=${() => {
      // Catch-all for DelegateFocus, without this the "month dropdown" will by default receive focus instead.
      this.shadowRoot?.querySelector<HTMLElement>('[role="grid"]')!.focus();
    }}
      >
        <div class="datepickers-container">
          ${DatePicker.call(this, {
      type: 'primary',
      value: this.#typedValue,
      min: this.#min,
      max: this.#max,
      onActiveChange: newActiveDate => {
        this._activeDates = [newActiveDate, isoDate.clamp(this._activeDates[1], isoDate.getFirstDateInMonth(isoDate.addMonths(newActiveDate, 1)), undefined)];
      }
    })}
          ${!this.range ? null : DatePicker.call(this, {
      type: 'secondary',
      value: this.#typedValue,
      min: this.#min,
      max: this.#max,
      onActiveChange: newFutureActiveDate => {
        this._activeDates = [isoDate.clamp(this._activeDates[0], undefined, isoDate.getFirstDateInMonth(isoDate.addMonths(newFutureActiveDate, -1))), newFutureActiveDate];
      }
    })}
        </div>

        <button-4af9c8ad5eff83098c1a5f4df9a6297c
          label=${translations.clear}
          purpose="notice"
          implicit
          weight="low"
          .disabled=${this.disabled}
          tabindex="-1"
          aria-hidden="true"
          @click=${() => {
      this._userSelectDate(null);
      this.#resetActiveDates();
    }}
        ></button-4af9c8ad5eff83098c1a5f4df9a6297c>
      </div>
    `;
  }
  #checkAndEnsureValue() {
    const value = this.value as unknown;
    const clearValue = () => {
      this.value = [];
      this.#typedValue = [];
    };
    if (!Array.isArray(value) || !value.length) return clearValue();
    if (this.range) {
      if (value.length !== 2) return clearValue();
      const [startDate, endDate] = value.map(x => isoDate.tryParse(x));
      if (!startDate || !endDate) return clearValue();
      if (startDate > endDate) return clearValue();
      this.#typedValue = [startDate, endDate];
      return;
    }
    if (value.length !== 1) return clearValue();
    const singleDate = isoDate.tryParse(value[0]);
    if (!singleDate) return clearValue();
    this.#typedValue = [singleDate];
  }
  protected _userSelectDate(date: isoDateString | null) {
    if (this.disabled || date && !isoDate.isInRange(date, this.#min, this.#max)) {
      return;
    }
    const dispatchAndClose = () => {
      this.dispatchEvent(new Event('input'));
      this.dispatchEvent(new Event('change'));
      this._popoutContext.closePopout();
    };
    if (date === null) {
      this._applyUserValue([]);
      dispatchAndClose();
      return;
    }
    if (this.range) {
      if (this._pendingUnsortedRangeValue === null) {
        this._pendingUnsortedRangeValue = [date, date];
        return;
      }
      const [startDate, endDate] = [this._pendingUnsortedRangeValue[0], date].toSorted();
      this._applyUserValue([startDate, endDate]);
      this._clearPendingRange();
      dispatchAndClose();
      return;
    }
    this._applyUserValue([date]);
    dispatchAndClose();
  }
  protected _updatePendingRangeTail(tailDate: isoDateString) {
    if (!this._pendingUnsortedRangeValue || tailDate === this._pendingUnsortedRangeValue[1]) return;
    this._pendingUnsortedRangeValue = [this._pendingUnsortedRangeValue[0], tailDate];
  }
  protected _clearPendingRange() {
    this._pendingUnsortedRangeValue = null;
  }
  #resetActiveDates() {
    let [startDate, endDate] = this.#typedValue;
    startDate = startDate || this.#at || isoDate.today();
    endDate = isoDate.clamp(endDate || startDate, isoDate.getFirstDateInMonth(isoDate.addMonths(startDate, 1)));
    this._activeDates = [startDate, endDate];
  }
}