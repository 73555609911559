import { html } from 'lit';
import { register as _registerElement } from "../../../one-ux/elements/one-ux-dialog/register-element.js";
import { register as _registerElement2 } from "../../../one-ux/elements/one-ux-text/register-element.js";
import { register as _registerElement3 } from "../../../one-ux/elements/one-ux-button/register-element.js";
import { register as _registerElement4 } from "../../../one-ux/elements/one-ux-icon/register-element.js";
_registerElement4("icon-4b92a46b0e83160b302a6fb46bd5c8cd");
_registerElement3("button-b26ba42643ca2b10086646bc058ae345");
_registerElement2("text-8e171938d2802a322fc91b0a980b9b54");
_registerElement("dialog-3bdc1596cb025c8290c860f3a992f1be");
export interface IPromptModalData {
  lang: 'sv' | 'en';
  type: 'alert' | 'confirm';
  header: string;
  content: string | HTMLElement;
  ok: string;
  okIcon: string;
  cancel?: string;
  cancelIcon?: string;
  open: boolean;
  danger?: boolean;
}
export function PromptModal(options: IPromptModalData & {
  onClose: (result: boolean) => void;
}) {
  const confirm = options.type === 'confirm';
  return html`<dialog-3bdc1596cb025c8290c860f3a992f1be
    id="__PDR_prompt_modal__"
    style="top: 20%; width: 95%; max-width: 480px; max-height: 60%;"
    @close=${() => options.onClose(false)}
  >
    <text-8e171938d2802a322fc91b0a980b9b54 slot="header" lang="${options.lang}">${options.header || html`&nbsp;`}</text-8e171938d2802a322fc91b0a980b9b54>
    <div slot="content">
      ${options.content instanceof HTMLElement ? options.content : html`<p style="white-space: pre-wrap">${options.content}</p>`}
    </div>
    ${!confirm ? null : html`<button-b26ba42643ca2b10086646bc058ae345
          slot="footer-end"
          lang=${options.lang}
          label=${options.cancel!}
          @click=${() => {
    options.onClose(false);
  }}
        >
          ${ButtonContent(options.cancel!, options.cancelIcon)}
        </button-b26ba42643ca2b10086646bc058ae345>`}
    <button-b26ba42643ca2b10086646bc058ae345
      slot="footer-end"
      lang="${options.lang}"
      purpose=${confirm && options.danger ? 'caution' : 'main'}
      label=${options.ok}
      @click=${() => {
    options.onClose(true);
  }}
    >
      ${ButtonContent(options.ok, options.okIcon)}
    </button-b26ba42643ca2b10086646bc058ae345>
  </dialog-3bdc1596cb025c8290c860f3a992f1be>`;
}
function ButtonContent(text: string, icon?: string | {
  sheet: string;
  icon: string;
}) {
  if (!icon) {
    return text;
  }
  if (typeof icon === 'string') {
    icon = {
      sheet: 'default',
      icon: icon
    };
  }
  return html`<icon-4b92a46b0e83160b302a6fb46bd5c8cd set="${icon!.sheet as any}" icon="${icon!.icon as any}" slot="start"></icon-4b92a46b0e83160b302a6fb46bd5c8cd>${text}`;
}