import { html } from 'lit';
import type { OneUxDatePickerElement } from '../OneUxDatePickerElement.js';
import { createRef, ref, Ref } from 'lit/directives/ref.js';
import { isoDate, isoDateString } from '../IsoDate.js';
import { keyCodes } from '../../../utils.js';
import { Optional } from '../../../types.js';
import { getLanguage } from '../language.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { register as _registerElement } from "../../one-ux-icon/register-element.js";
_registerElement("icon-62539e5c91720ca197e02596549694f6");
const style = html`
  <style>
    .one-ux-date-picker-element--preview-container {
      display: inline-flex;
      gap: var(--one-ux-spacing--small);
      align-items: center;

      .one-ux-date-picker-element--preview-input {
        all: unset;
        width: 90px;
        line-height: 20px;
        padding-top: 2px;
        box-sizing: border-box;
        cursor: text;
        flex-grow: 1;
        color: var(--one-ux-palette--brand-neutral-600);

        &::placeholder {
          color: var(--one-ux-palette--brand-neutral-400);
          overflow: visible;
        }
      }
    }
  </style>
`;
export function DropdownPreview(this: OneUxDatePickerElement, {
  value,
  min,
  max
}: {
  value: isoDateString[];
  min: Optional<isoDateString>;
  max: Optional<isoDateString>;
}) {
  const {
    translations,
    lang
  } = getLanguage(this);
  const startDateRef = createRef<HTMLInputElement>();
  const endDateRef = createRef<HTMLInputElement>();
  const tryGetConcatenatedFormat = (value: string) => {
    const longConcatenatedIsoFormat = /^\d{8}$/;
    const shortConcatenatedIsoFormat = /^\d{6}$/;
    if (longConcatenatedIsoFormat.test(value)) {
      return [value.substring(0, 4), value.substring(4, 6), value.substring(6, 8)].join('-');
    }
    if (shortConcatenatedIsoFormat.test(value)) {
      return ['20' + value.substring(0, 2), value.substring(2, 4), value.substring(4, 6)].join('-');
    }
    return value;
  };
  const parseValue = ($input: HTMLInputElement): isoDateString | undefined | null => {
    if (!$input.value) return null;
    const date = isoDate.tryParse(tryGetConcatenatedFormat($input.value));
    return date ? isoDate.clamp(date, min, max) : undefined;
  };
  const dirtyValue = (parsedStartValue: isoDateString | null, parsedEndValue: isoDateString | null) => {
    return parsedStartValue != value[0] || this.range && parsedEndValue != value[1];
  };
  const commitValue = () => {
    const parsedStartValue = parseValue(startDateRef.value!);
    startDateRef.value!.value = value[0] || '';
    if (this.range) {
      const parsedEndValue = parseValue(endDateRef.value!);
      endDateRef.value!.value = value[1] || '';
      const hasNotChanged = parsedStartValue === undefined || parsedEndValue === undefined || !dirtyValue(parsedStartValue, parsedEndValue);
      if (hasNotChanged) return;
      if (parsedStartValue && parsedEndValue) {
        if (parsedStartValue > parsedEndValue) return;
        this._userSelectDate(parsedStartValue);
        this._userSelectDate(parsedEndValue);
        return;
      }
      if (parsedStartValue === null && parsedStartValue === null) {
        this._userSelectDate(null);
        return;
      }
      return;
    }
    const hasNotChanged = parsedStartValue === undefined || !dirtyValue(parsedStartValue, null);
    if (hasNotChanged) return;
    this._userSelectDate(parsedStartValue);
  };
  const handleBlur = (event: any) => {
    this._previewHasFocus = false;
    const stillEditing = this.range && (event.relatedTarget === startDateRef.value || event.relatedTarget === endDateRef.value);
    if (stillEditing) {
      startDateRef.value!.value = parseValue(startDateRef.value!) || '';
      endDateRef.value!.value = parseValue(endDateRef.value!) || '';
      return;
    }
    commitValue();
  };
  const handleKeydown = (event: KeyboardEvent) => {
    const handled = () => {
      event.preventDefault();
      event.stopPropagation();
    };
    switch (event.code) {
      case keyCodes.RETURN:
      case keyCodes.NUMPADRETURN:
        commitValue();
        return handled();
      case keyCodes.UP:
      case keyCodes.DOWN:
      case keyCodes.SPACE:
        startDateRef.value!.value = value[0] || '';
        if (this.range) {
          endDateRef.value!.value = value[1] || '';
        }
        if (this._popoutContext.isOpen) {
          this.focus();
        } else {
          this._popoutContext.openPopout();
        }
        return handled();
      case keyCodes.ESCAPE:
        {
          const startRawText = value[0] || '';
          const endRawText = value[1] || '';
          let hasResetText = false;
          if (startDateRef.value!.value !== startRawText) {
            startDateRef.value!.value = startRawText;
            hasResetText = true;
          }
          if (this.range && endDateRef.value!.value !== endRawText) {
            endDateRef.value!.value = endRawText;
            hasResetText = true;
          }
          if (!hasResetText) {
            this._popoutContext.closePopout(true);
          }
          return handled();
        }
    }
  };
  const getLimitsTooltipHint = () => {
    if (!this.min && !this.max) {
      return undefined;
    }
    const result = [];
    if (this.min) {
      result.push(`Min: ${this.min}`);
    }
    if (this.max) {
      result.push(`Max: ${this.max}`);
    }
    return result.join(' ');
  };
  const Combobox = ({
    reference,
    value,
    placeholder
  }: {
    reference: Ref<HTMLInputElement>;
    value: Optional<isoDateString>;
    placeholder?: string;
  }) => html`
    <input
      ${ref(reference)}
      class="one-ux-date-picker-element--preview-input"
      .value=${value || ''}
      .disabled=${this._dropdownContext.disabled || this.disabled}
      .placeholder=${placeholder !== undefined ? placeholder || translations.preview.isoFormatPlaceholder : ''}
      tabindex=${ifDefined(this.hasKeyboardFocus ? -1 : undefined)}
      role="combobox"
      aria-label=${this.range ? reference === startDateRef ? translations.preview.dateRangeStart : translations.preview.dateRangeEnd : translations.preview.date}
      aria-expanded=${this._popoutContext.isOpen}
      aria-required=${this.required}
      one-ux-tooltip=${ifDefined(getLimitsTooltipHint())}
      @focus=${() => {
    this._previewHasFocus = true;
  }}
      @blur=${handleBlur}
      @keydown=${handleKeydown}
      @beforeinput=${(event: any) => {
    if (event.data && /[^0-9-.,]/.test(event.data)) {
      event.preventDefault();
    }
  }}
      @click=${(event: any) => {
    event.stopPropagation();
    this._popoutContext.openPopout(true);
  }}
    />
  `;
  return html`
    ${style}
    <div lang=${lang} class="one-ux-date-picker-element--preview-container">
      ${Combobox({
    reference: startDateRef,
    value: value[0],
    placeholder: this._dropdownContext.placeholder
  })}
      ${!this.range ? null : html`
            <icon-62539e5c91720ca197e02596549694f6
              set="internal"
              icon="date-picker-range-separator"
              size="200"
              color="brand-neutral-600"
            ></icon-62539e5c91720ca197e02596549694f6>
            ${Combobox({
    reference: endDateRef,
    value: value[1],
    placeholder: this._dropdownContext.placeholder ? '...' : ''
  })}
          `}
    </div>
  `;
}