import { html, TemplateResult } from 'lit';
import { register as _registerElement } from "../one-ux-link/register-element.js";
_registerElement("link-93f514380f031bf18fb02304f7ee8aa9");
export function parseLinks(content: string, options: {
  parse?: boolean;
  parseLinks?: boolean;
  parseEmails?: boolean;
}): string | TemplateResult | (string | TemplateResult)[] {
  if (!options.parse) {
    return content;
  }
  let parts = [] as (string | TemplateResult)[];
  const markdownStyleRegex = /\[(.*?)\]\((.*?)\)/g;
  let match = markdownStyleRegex.exec(content);
  let startIndex = 0;
  while (match !== null) {
    const [link, text, url] = match;
    const previousText = content.substring(startIndex, match.index);
    if (previousText) {
      parts.push(previousText);
    }
    startIndex = match.index + link.length;
    parts.push(html`<link-93f514380f031bf18fb02304f7ee8aa9 implicit open="new" label=${text} url=${url}></link-93f514380f031bf18fb02304f7ee8aa9>`);
    match = markdownStyleRegex.exec(content);
  }
  const restText = content.substring(startIndex);
  if (restText) {
    parts.push(restText);
  }
  if (options.parseLinks) {
    parts = parts.flatMap(part => {
      if (typeof part !== 'string') {
        return part;
      }
      const linkRegex = /(?:(?:https?:\/\/)|(?:mailto:)|(?:tel:))(?:[^\s"']+)/g;
      return applyUrlTransform(linkRegex, part);
    });
  }

  // Emails have to be parsed after links to that the email from
  // mailto:email@example.com is not picked up by the email parsing if link parsing is also enabled.
  if (options.parseEmails) {
    parts = parts.flatMap(part => {
      if (typeof part !== 'string') {
        return part;
      }
      const emailRegex = /[^\s([{<}"']+@[^\s]+/g;
      return applyUrlTransform(emailRegex, part, url => {
        const mailToString = 'mailto:';
        if (url.startsWith(mailToString)) {
          return url;
        }
        return mailToString + url;
      });
    });
  }
  if (parts.length === 1) {
    return parts[0];
  }
  return parts;
}
function applyUrlTransform(urlRegex: RegExp, part: string, urlMapper = (url: string) => url) {
  const subParts = [] as (string | TemplateResult)[];
  let match = urlRegex.exec(part);
  let startIndex = 0;
  while (match !== null) {
    const previousText = part.substring(startIndex, match.index);
    const url = cleanUrl(previousText, match[0]);
    if (previousText) {
      subParts.push(previousText);
    }
    startIndex = match.index + url.length;
    const text = stripProtocol(url);
    subParts.push(html`<link-93f514380f031bf18fb02304f7ee8aa9 implicit open="new" label=${text} url=${urlMapper(url)}></link-93f514380f031bf18fb02304f7ee8aa9>`);
    match = urlRegex.exec(part);
  }
  const restText = part.substring(startIndex);
  if (restText) {
    subParts.push(restText);
  }
  return subParts;
}
function stripProtocol(url: string): string {
  const mailToString = 'mailto:';
  if (url.startsWith(mailToString)) {
    return url.slice(mailToString.length);
  }
  const telString = 'tel:';
  if (url.startsWith(telString)) {
    return url.slice(telString.length);
  }
  return url;
}
function cleanUrl(previousText: string, url: string): string {
  const previousChar = previousText[previousText.length - 1];
  let lastChar = url[url.length - 1];
  // Doing this manually  instead of with [].includes is deliberate for performance.
  const hasPunctuation = lastChar === '.' || lastChar === ',' || lastChar === ':' || lastChar === ';' || lastChar === '!' || lastChar === '?';

  // Manage punctuation first so that "test (http://test), test" works properly
  if (hasPunctuation) {
    url = url.slice(0, -1);
    lastChar = url[url.length - 1];
  }
  const isWrapped = previousChar === '(' && lastChar === ')' || previousChar === '[' && lastChar === ']' || previousChar === '{' && lastChar === '}' || previousChar === '<' && lastChar === '>' || previousChar === '"' && lastChar === '"' || previousChar === "'" && lastChar === "'";
  if (isWrapped) {
    url = url.slice(0, -1);
  }
  return url;
}