import { html } from 'lit';
import type { OneUxDatePickerElement } from '../OneUxDatePickerElement.js';
import { type isoDateString, isoDate } from '../IsoDate.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { keyCodes } from '../../../utils.js';
import { Navigation } from './Navigation.js';
import { buildIso8601CalendarModel } from '../calendar.js';
import { getLanguage } from '../language.js';
import { Optional } from '../../../types.js';
export function DatePicker(this: OneUxDatePickerElement, {
  type,
  value,
  min,
  max,
  onActiveChange
}: {
  type: 'primary' | 'secondary';
  value: isoDateString[];
  min: Optional<isoDateString>;
  max: Optional<isoDateString>;
  onActiveChange: (activeDate: isoDateString) => void;
}) {
  const {
    translations
  } = getLanguage(this);
  const activeDateId = `active-date-${type}`;
  const screenReaderFeedbackId = `screen-reader-feedback`; // Feedback is shared between both calendars
  const [activeDate, otherActiveDate] = type === 'primary' ? this._activeDates : this._activeDates.toReversed();
  const calendarModel = buildIso8601CalendarModel({
    activeDate,
    otherActiveDate,
    value,
    pendingUnsortedRangeValue: this._pendingUnsortedRangeValue,
    min,
    max,
    disabled: this.disabled
  });
  const handleKeyDown = (event: KeyboardEvent & {
    target: HTMLElement;
  }) => {
    const handled = () => {
      event.preventDefault();
      event.stopPropagation();
    };
    const updateActiveDate = (newActiveDate: isoDateString) => {
      handled();
      onActiveChange(newActiveDate);
      if (this._pendingUnsortedRangeValue && !this.disabled && isoDate.isInRange(newActiveDate, min, max)) {
        this._updatePendingRangeTail(newActiveDate);
      }
    };
    switch (event.code) {
      case keyCodes.LEFT:
        return updateActiveDate(isoDate.addDays(activeDate, -1));
      case keyCodes.RIGHT:
        return updateActiveDate(isoDate.addDays(activeDate, 1));
      case keyCodes.UP:
        return updateActiveDate(isoDate.addDays(activeDate, -7));
      case keyCodes.DOWN:
        return updateActiveDate(isoDate.addDays(activeDate, 7));
      case keyCodes.PAGEUP:
        return updateActiveDate(event.shiftKey ? isoDate.addYears(activeDate, -1) : isoDate.addMonths(activeDate, -1));
      case keyCodes.PAGEDOWN:
        return updateActiveDate(event.shiftKey ? isoDate.addYears(activeDate, 1) : isoDate.addMonths(activeDate, 1));
      case keyCodes.HOME:
        return updateActiveDate(event.shiftKey && this.range && value[0] ? value[0] : calendarModel.firstDayInActiveWeek);
      case keyCodes.END:
        return updateActiveDate(event.shiftKey && this.range && value[1] ? value[1] : calendarModel.lastDayInActiveWeek);
      case keyCodes.RETURN:
      case keyCodes.SPACE:
        this._userSelectDate(activeDate);
        return handled();
      case keyCodes.DELETE:
      case keyCodes.BACKSPACE:
        this._userSelectDate(null);
        return handled();
      case keyCodes.ESCAPE:
        if (this._pendingUnsortedRangeValue) {
          this._clearPendingRange();
          handled();
        }
        return;
    }
  };
  const getScreenReaderFeedback = () => {
    if (this._pendingUnsortedRangeValue) {
      return `${translations.selectionOfDateRangeStarted}: ${this._pendingUnsortedRangeValue[0]}`;
    }
    if (this.range) {
      return this.empty ? translations.noDateRangeSelected : `${translations.selectedDateRange}: ${this.value.join(', ')}`;
    }
    return this.empty ? translations.noDateSelected : `${translations.selectedDate}: ${this.value[0]}`;
  };
  return html`
    <div class="datepicker">
      ${Navigation.call(this, {
    activeDate,
    monthNames: translations.months,
    onActiveChange
  })}

      <table
        class="calendar"
        role="grid"
        tabindex="${this.disabled || this._previewHasFocus || type == 'secondary' ? -1 : 0}"
        aria-hidden="${ifDefined(type === 'secondary' ? '' : undefined)}"
        aria-describedby=${screenReaderFeedbackId}
        aria-roledescription=${translations.calendar}
        aria-activedescendant=${activeDateId}
        aria-disabled=${this.disabled}
        aria-multiselectable="${this.range}"
        aria-required=${this.required}
        @keydown=${handleKeyDown}
      >
        <thead>
          <tr>
            <td>&nbsp;</td>
            ${translations.weekdays.map(weekday => html`<th scope="col" aria-label=${weekday.long}>${weekday.short}</th>`)}
          </tr>
        </thead>

        <tbody>
          ${calendarModel.weeks.map(week => html`
              <tr>
                <th scope="row" aria-label=${`${translations.week} ${week.number}`}>
                  ${`${translations.week[0].toLowerCase()}. ${week.number}`}
                </th>

                ${week.dates.map(date => {
    const [year, month, day] = isoDate.getParts(date.value);
    return html`<td
                    id=${ifDefined(date.isActive ? activeDateId : undefined)}
                    class=${classMap({
      date: true,
      'range-start': date.isRangeStart,
      'range-end': date.isRangeEnd,
      'in-range': date.isInRange,
      today: date.isToday,
      active: date.isActive,
      padding: date.isPadding,
      overlapping: date.isOverlapping
    })}
                    aria-label=${`${day} ${translations.months[month - 1]} ${year}`}
                    aria-selected=${date.isSelected}
                    aria-disabled=${date.isDisabled}
                    one-ux-tooltip=${ifDefined(date.isDisabled ? translations.notSelectable : date.isToday ? translations.todaysDate : undefined)}
                    @mousedown=${() => {
      if (!date.isDisabled) {
        onActiveChange(date.value);
        this._userSelectDate(date.value);
      }
    }}
                    @mousemove=${() => {
      // Using mousemove instead of mouseenter to ensure passive cursor do not affect keyboard users
      if (this._pendingUnsortedRangeValue && !date.isDisabled) {
        this._updatePendingRangeTail(date.value);
      }
    }}
                  >
                    <div class="date-active-highlight"></div>
                    <div class="date-text">${day}</div>
                  </td>`;
  })}
              </tr>
            `)}
        </tbody>
      </table>

      ${type === 'secondary' ? null : html`
            <div
              id=${screenReaderFeedbackId}
              aria-live="${ifDefined(this.hasKeyboardFocus ? 'assertive' : undefined)}"
              class="one-ux-accessibility--screen-reader"
            >
              ${getScreenReaderFeedback()}
            </div>
          `}
    </div>
  `;
}