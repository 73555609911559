import type { OneUxPopoutElement } from '../elements/one-ux-popout/OneUxPopoutElement.js';
import { html, nothing } from 'lit';
import { styleMap } from 'lit/directives/style-map.js';
import { register as _registerElement } from "../elements/one-ux-popout/register-element.js";
import { register as _registerElement2 } from "../elements/one-ux-icon/register-element.js";
_registerElement2("icon-1057be8aa72010658d0ae8da0a8516d5");
_registerElement("popout-a47ef10fc05457e21bca317a2fb6bee6");
type errorPopoutOptions = {
  errors: string[];
  reference: OneUxPopoutElement['reference'];
  hidden: boolean;
};
export function ErrorsPopout({
  errors,
  reference,
  hidden
}: errorPopoutOptions) {
  if (errors.length === 0 || hidden) {
    return nothing;
  }
  return html`<popout-a47ef10fc05457e21bca317a2fb6bee6
    class="errors-popout"
    indent-top="small"
    indent-right="large"
    indent-bottom="small"
    indent-left="normal"
    .reference=${reference}
  >
    <div
      style=${styleMap({
    display: 'flex',
    gap: 'var(--one-ux-spacing--small)',
    'align-items': 'flex-start'
  })}
    >
      <icon-1057be8aa72010658d0ae8da0a8516d5 style="color: var(--one-ux-palette--red-300)" size="300" icon="warning"></icon-1057be8aa72010658d0ae8da0a8516d5>
      <ul
        style=${styleMap({
    margin: '0',
    padding: '0',
    'padding-top': '2px'
  })}
      >
        ${errors.map(error => html` <li
              style=${styleMap({
    display: 'flex',
    font: 'var(--one-ux-font--body-300)',
    'letter-spacing': 'var(--one-ux-letter-spacing--body-300)',
    color: 'var(--one-ux-palette--brand-neutral-600)'
  })}
            >
              ${errors.length > 1 ? `• ${error}` : error}
            </li>`)}
      </ul>
    </div>
  </popout-a47ef10fc05457e21bca317a2fb6bee6>`;
}