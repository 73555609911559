import { css, unsafeCSS } from 'lit';
import { SEND_TO_SHADOW_REALM } from '../../utils/css.js';
import oneUxSpacing from '../../generated/json/spacing/spacing.json';
export const maskStart = parseInt(oneUxSpacing['700']);
export const maskStretch = parseInt(oneUxSpacing['700']) * 2;
export const style = css`
  :host {
    --one-ux-tabs--background-color: var(--one-ux-palette--gray-100);

    width: 100%;
  }

  .one-ux-element--root {
    display: flex;
    flex-direction: column;
  }

  .header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &.has-header-end-content {
      gap: 16px;
    }

    slot[name='header-end'] {
      align-items: center;
      gap: var(--one-ux-spacing--300);
      padding: var(--one-ux-spacing--200) var(--one-ux-spacing--500) var(--one-ux-spacing--200) 0;

      .has-header-end-content & {
        display: flex;
      }
    }

    .tablist-nav {
      display: flex;
      flex: 1 0 0%;
      overflow: auto;
      position: relative;

      [one-ux-element='one-ux-button'] {
        position: absolute;
        z-index: 1;
      }

      .nav-left {
        left: var(--one-ux-spacing--200);
        bottom: var(--one-ux-spacing--200);
      }
      .nav-right {
        right: var(--one-ux-spacing--200);
        bottom: var(--one-ux-spacing--200);
      }

      &.state-overflow-none .nav-left,
      &.state-overflow-none .nav-right {
        ${SEND_TO_SHADOW_REALM};
      }

      &.state-overflow-left .nav-left {
        ${SEND_TO_SHADOW_REALM};
      }

      &.state-overflow-right .nav-right {
        ${SEND_TO_SHADOW_REALM};
      }
    }

    [role='tablist'] {
      /* Based on "--one-ux-shadow--drop-200" spread value divided by two */
      --box-shadow-offset: 2.25px;
      --one-ux-tabs--mask-left: linear-gradient(
        to left,
        transparent ${unsafeCSS(maskStart)}px,
        currentcolor ${unsafeCSS(maskStretch)}px
      );
      --one-ux-tabs--mask-right: linear-gradient(
        to right,
        transparent ${unsafeCSS(maskStart)}px,
        currentcolor ${unsafeCSS(maskStretch)}px
      );
      --one-ux-tabs--mask-hard-left: linear-gradient(
        to left,
        transparent calc(${unsafeCSS(maskStart)}px - var(--box-shadow-offset)),
        currentcolor 0
      );
      --one-ux-tabs--mask-hard-right: linear-gradient(
        to right,
        transparent calc(${unsafeCSS(maskStart)}px - var(--box-shadow-offset)),
        currentcolor 0
      );

      display: flex;
      overflow: auto;
      position: relative;
      flex-grow: 1;
      padding: calc(var(--one-ux-spacing--400) / 2) var(--one-ux-spacing--500) 0;

      .has-header-end-content & {
        padding-right: 0;
      }

      scroll-behavior: smooth;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

      .state-overflow-both &[scroll-direction='none'] {
        mask-image: var(--one-ux-tabs--mask-left), var(--one-ux-tabs--mask-right);
        mask-composite: intersect;
      }

      .state-overflow-both &[scroll-direction='right'] {
        mask-image: var(--one-ux-tabs--mask-right), var(--one-ux-tabs--mask-hard-left);
        mask-composite: intersect;
      }

      .state-overflow-both &[scroll-direction='left'] {
        mask-image: var(--one-ux-tabs--mask-left), var(--one-ux-tabs--mask-hard-right);
        mask-composite: intersect;
      }

      .state-overflow-left &:not([scroll-direction='right']) {
        mask-image: var(--one-ux-tabs--mask-left);
      }

      .state-overflow-right &:not([scroll-direction='left']) {
        mask-image: var(--one-ux-tabs--mask-right);
      }
    }
  }

  .tabpanel-container {
    background-color: var(--one-ux-tabs--background-color);
    border-radius: var(--one-ux-radius--500);
    box-shadow: var(--one-ux-shadow--drop-300);
    padding: var(--one-ux-spacing--500);
    overflow: hidden;
    flex: 1;
    position: relative;
    z-index: 1;

    &:has(.has-nested-tabs.is-selected) {
      padding: var(--one-ux-spacing--400) 0 0;
    }
  }

  [role='tabpanel'] {
    z-index: 1;

    &.is-selected {
      position: relative;
      opacity: 1;
      visibility: visible;
      z-index: 1;
    }

    &:not(.is-selected) {
      position: absolute;
      top: var(--one-ux-spacing--500);
      width: calc(100% - var(--one-ux-spacing--500) * 2);
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }

    &:focus {
      outline: var(--one-ux-border-width--500) solid var(--one-ux-palette--keyboard-focus-400);
      outline-offset: var(--one-ux-spacing--200);
    }
  }

  /**
   * Implicit
   */
  :host(:not([implicit])) ::slotted([one-ux-element='one-ux-tab']) {
    --one-ux-tab--explicit-selected-background-color: var(--one-ux-tabs--background-color);
  }

  :host([implicit]) .header::before {
    content: '';
    border-bottom: 1px solid var(--one-ux-palette--brand-neutral-200);
    position: absolute;
    bottom: 0;
    left: var(--one-ux-spacing--500);
    right: var(--one-ux-spacing--500);
  }

  :host([implicit]) .tabpanel-container {
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
  }

  .tabpanel-container {
    &[state-collapse-edge~='bottom' i] {
      border-radius: var(--one-ux-radius--500) var(--one-ux-radius--500) 0 0;
      clip-path: inset(-10px -10px 0px -10px);
    }

    &[state-collapse-edge~='left' i] {
      border-radius: 0 var(--one-ux-radius--500) var(--one-ux-radius--500) 0;
      clip-path: inset(-10px -10px -10px 0px);
    }

    &[state-collapse-edge~='right' i] {
      border-radius: var(--one-ux-radius--500) 0 0 var(--one-ux-radius--500);
      clip-path: inset(-10px 0 -10px -10px);
    }

    &[state-collapse-edge~='right' i][state-collapse-edge~='bottom' i] {
      border-radius: var(--one-ux-radius--500) 0 0 0;
      clip-path: inset(-10px 0 0 -10px);
    }

    &[state-collapse-edge~='left' i][state-collapse-edge~='bottom' i] {
      border-radius: 0 var(--one-ux-radius--500) 0 0;
      clip-path: inset(-10px -10px 0 0);
    }

    &[state-collapse-edge~='left' i][state-collapse-edge~='right' i] {
      border-radius: 0;
      clip-path: inset(-10px 0 -10px 0);
    }

    &[state-collapse-edge~='left' i][state-collapse-edge~='right' i][state-collapse-edge~='bottom' i] {
      border-radius: 0;
      clip-path: inset(-10px 0 0 0);
    }

    :host([implicit]) &[state-collapse-edge] {
      border-radius: unset;
      clip-path: unset;
    }
  }
`;