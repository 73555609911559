import { register as registerOneUxAdapter } from './one-ux-adapter/register-element.js';
import { register as registerOneUxAvatar } from './one-ux-avatar/register-element.js';
import { register as registerOneUxBadge } from './one-ux-badge/register-element.js';
import { register as registerOneUxBusy } from './one-ux-busy/register-element.js';
import { register as registerOneUxButton } from './one-ux-button/register-element.js';
import { register as registerOneUxCheckboxGroup } from './one-ux-checkbox-group/register-element.js';
import { register as registerOneUxCheckbox } from './one-ux-checkbox/register-element.js';
import { register as registerOneUxCollapsibleSection } from './one-ux-collapsible-section/register-element.js';
import { register as registerOneUxColorPicker } from './one-ux-color-picker/register-element.js';
import { register as registerOneUxContextProvider } from './one-ux-context-provider/register-element.js';
import { register as registerOneUxContextualTree } from './one-ux-contextual-tree/register-element.js';
import { register as registerOneUxDatePicker } from './one-ux-date-picker/register-element.js';
import { register as registerOneUxDialog } from './one-ux-dialog/register-element.js';
import { register as registerOneUxDropdown } from './one-ux-dropdown/register-element.js';
import { register as registerOneUxEditableText } from './one-ux-editable-text/register-element.js';
import { register as registerOneUxField } from './one-ux-field/register-element.js';
import { register as registerOneUxFieldSet } from './one-ux-field-set/register-element.js';
import { register as registerOneUxIcon } from './one-ux-icon/register-element.js';
import { register as registerOneUxInput } from './one-ux-input/register-element.js';
import { register as registerOneUxInteractiveContent } from './one-ux-interactive-content/register-element.js';
import { register as registerOneUxLabel } from './one-ux-label/register-element.js';
import { register as registerOneUxLink } from './one-ux-link/register-element.js';
import { register as registerOneUxList } from './one-ux-list/register-element.js';
import { register as registerOneUxMenu } from './one-ux-menu/register-element.js';
import { register as registerOneUxMessage } from './one-ux-message/register-element.js';
import { register as registerOneUxPill } from './one-ux-pill/register-element.js';
import { register as registerOneUxPopout } from './one-ux-popout/register-element.js';
import { register as registerOneUxRadio } from './one-ux-radio/register-element.js';
import { register as registerOneUxRadioGroup } from './one-ux-radio-group/register-element.js';
import { register as registerOneUxScroll } from './one-ux-scroll/register-element.js';
import { register as registerOneUxSearch } from './one-ux-search/register-element.js';
import { register as registerOneUxSlider } from './one-ux-slider/register-element.js';
import { register as registerOneUxSwitch } from './one-ux-switch/register-element.js';
import { register as registerOneUxTab } from './one-ux-tab/register-element.js';
import { register as registerOneUxTabNext } from './one-ux-tab-next/register-element.js';
import { register as registerOneUxTabpanelNext } from './one-ux-tabpanel-next/register-element.js';
import { register as registerOneUxTabs } from './one-ux-tabs/register-element.js';
import { register as registerOneUxTabsNext } from './one-ux-tabs-next/register-element.js';
import { register as registerOneUxTabsPreview } from './one-ux-tabs-preview/register-element.js';
import { register as registerOneUxText } from './one-ux-text/register-element.js';
import { register as registerOneUxTextarea } from './one-ux-textarea/register-element.js';
import { register as registerOneUxToast } from './one-ux-toast/register-element.js';
import { register as registerOneUxTooltip } from './one-ux-tooltip/register-element.js';
import { register as registerOneUxTree } from './one-ux-tree/register-element.js';
import { register as registerOneUxWidgetButton } from './one-ux-widget-button/register-element.js';
registerOneUxAdapter('one-ux-adapter');
registerOneUxAvatar('one-ux-avatar');
registerOneUxBadge('one-ux-badge');
registerOneUxBusy('one-ux-busy');
registerOneUxButton('one-ux-button');
registerOneUxCheckbox('one-ux-checkbox');
registerOneUxCheckboxGroup('one-ux-checkbox-group');
registerOneUxCollapsibleSection('one-ux-collapsible-section');
registerOneUxColorPicker('one-ux-color-picker');
registerOneUxContextProvider('one-ux-context-provider');
registerOneUxContextualTree('one-ux-contextual-tree');
// TODO: Deprecate this
registerOneUxContextualTree('contextual-one-ux-tree');
registerOneUxDatePicker('one-ux-date-picker');
registerOneUxDialog('one-ux-dialog');
registerOneUxDropdown('one-ux-dropdown');
registerOneUxEditableText('one-ux-editable-text');
registerOneUxField('one-ux-field');
registerOneUxFieldSet('one-ux-field-set');
registerOneUxIcon('one-ux-icon');
registerOneUxInput('one-ux-input');
registerOneUxInteractiveContent('one-ux-interactive-content');
registerOneUxLabel('one-ux-label');
registerOneUxLink('one-ux-link');
registerOneUxList('one-ux-list');
registerOneUxMenu('one-ux-menu');
registerOneUxMessage('one-ux-message');
registerOneUxPill('one-ux-pill');
registerOneUxPopout('one-ux-popout');
registerOneUxRadio('one-ux-radio');
registerOneUxRadioGroup('one-ux-radio-group');
registerOneUxScroll('one-ux-scroll');
registerOneUxSearch('one-ux-search');
registerOneUxSlider('one-ux-slider');
registerOneUxSwitch('one-ux-switch');
registerOneUxTab('one-ux-tab');
registerOneUxTabNext('one-ux-tab-next');
registerOneUxTabpanelNext('one-ux-tabpanel-next');
registerOneUxTabs('one-ux-tabs');
registerOneUxTabsNext('one-ux-tabs-next');
registerOneUxTabsPreview('one-ux-tabs-preview');
registerOneUxText('one-ux-text');
registerOneUxTextarea('one-ux-textarea');
registerOneUxToast('one-ux-toast');
registerOneUxTooltip('one-ux-tooltip');
registerOneUxTree('one-ux-tree');
registerOneUxWidgetButton('one-ux-widget-button');