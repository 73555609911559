import { html } from 'lit';
import type { OneUxDatePickerElement } from '../OneUxDatePickerElement.js';
import { isoDateString, isoDate } from '../IsoDate.js';
import type { OneUxListElement } from '../../one-ux-list/OneUxListElement.js';
import { modulo } from '../../../utils/modulo.js';
import { register as _registerElement } from "../../one-ux-widget-button/register-element.js";
import { register as _registerElement2 } from "../../one-ux-adapter/register-element.js";
import { register as _registerElement3 } from "../../one-ux-dropdown/register-element.js";
import { register as _registerElement4 } from "../../one-ux-list/register-element.js";
_registerElement4("list-b2e6fb1d086a2c1e2fb06cdce3023753");
_registerElement3("dropdown-9be8814025fc498c7e0f609834876a58");
_registerElement2("adapter-5b3e16c0a4ec38996afebb76b218d8d4");
_registerElement("widget-button-38fe0341b8a8a86ecb00a7028ab07002");
export function Navigation(this: OneUxDatePickerElement, {
  activeDate,
  monthNames,
  onActiveChange
}: {
  activeDate: isoDateString;
  monthNames: string[];
  onActiveChange: (activeDate: isoDateString) => void;
}) {
  const numberOfMonthsBefore = 3;
  const numberOfMonthsAfter = 8;
  const [year, month] = isoDate.getParts(activeDate);
  const deltaYearOptions = Array.from({
    length: numberOfMonthsBefore + 1 + numberOfMonthsAfter
  }, (_, index) => {
    const deltaMonths = index - numberOfMonthsBefore;
    const accumulatedMonthValue = month - 1 + deltaMonths;
    const deltaYears = Math.floor(accumulatedMonthValue / 12);
    return {
      text: `${monthNames[modulo(accumulatedMonthValue, 12)]} ${year + deltaYears}`,
      value: deltaMonths
    };
  });
  return html`
    <div class="navigation" aria-hidden="true" ?inert=${this.disabled}>
      <widget-button-38fe0341b8a8a86ecb00a7028ab07002
        icon="toggle-left"
        @click=${() => onActiveChange(isoDate.addMonths(activeDate, -1))}
      ></widget-button-38fe0341b8a8a86ecb00a7028ab07002>

      <adapter-5b3e16c0a4ec38996afebb76b218d8d4 disable-notify>
        <dropdown-9be8814025fc498c7e0f609834876a58 tabindex="-1">
          <span slot="preview" class="month-and-year">${monthNames[month - 1]} ${year}</span>
          <list-b2e6fb1d086a2c1e2fb06cdce3023753
            .value=${[0]}
            .options=${deltaYearOptions}
            @input=${(e: Event & {
    target: OneUxListElement;
  }) => onActiveChange(isoDate.addMonths(activeDate, e.target.value[0] as number))}
          ></list-b2e6fb1d086a2c1e2fb06cdce3023753>
        </dropdown-9be8814025fc498c7e0f609834876a58>
      </adapter-5b3e16c0a4ec38996afebb76b218d8d4>

      <widget-button-38fe0341b8a8a86ecb00a7028ab07002
        icon="toggle-right"
        @click=${() => onActiveChange(isoDate.addMonths(activeDate, 1))}
      ></widget-button-38fe0341b8a8a86ecb00a7028ab07002>
    </div>
  `;
}