import { html } from 'lit';
import { ItemIcon } from '../types.js';
import paletteKeys from '../../../generated/json/palette/palette-keys.json';
import { styleMap } from 'lit/directives/style-map.js';
import { register as _registerElement } from "../../one-ux-icon/register-element.js";
_registerElement("icon-1aa25f67254100672862a7d8317a82a3");
export function Icon(icon?: ItemIcon) {
  if (!icon) {
    return null;
  }
  return html`<icon-1aa25f67254100672862a7d8317a82a3
    class="icon"
    set=${icon.set || 'default'}
    icon=${icon.name}
    size="300"
    style=${styleMap({
    color: icon.color ? paletteKeys.includes(icon.color) ? `var(--one-ux-palette--${icon.color})` : icon.color : null
  })}
  ></icon-1aa25f67254100672862a7d8317a82a3>`;
}