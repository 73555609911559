import { css } from 'lit';
export const style = css`
  :host {
    max-width: 200px !important;
    vertical-align: middle;
  }

  .one-ux-element--root {
    --one-ux-badge--height: 20px;
    --one-ux-badge--vertical-spacing: var(--one-ux-spacing--smaller);
    --one-ux-badge--left-spacing: var(--one-ux-spacing--small);
    --one-ux-badge--right-spacing: var(--one-ux-spacing--small);
    --one-ux-badge--internal-spacing: var(--one-ux-spacing--small);
    --one-ux-badge--font: var(--one-ux-font--mono-400);
    --one-ux-badge--letter-spacing: var(--one-ux-letter-spacing--mono-400);
    --one-ux-badge--icon-size: var(--one-ux-size--icon-100);

    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--one-ux-badge--internal-spacing);
    box-sizing: border-box;

    border-radius: var(--one-ux-radius--200);
    min-width: var(--one-ux-badge--height);

    height: var(--one-ux-badge--height);
    padding: var(--one-ux-badge--vertical-spacing) var(--one-ux-badge--right-spacing)
      var(--one-ux-badge--vertical-spacing) var(--one-ux-badge--left-spacing);

    background: var(--one-ux-badge--background-color);
    color: var(--one-ux-badge--text-color);

    font: var(--one-ux-badge--font);
    font-weight: 700;
    letter-spacing: var(--one-ux-badge--letter-spacing);
    cursor: default;
  }

  .one-ux-element--root::before {
    // Zero-width whitespace forces the base line to be the same regardless of icon
    content: attr(replicated-value) '\\200B';
    font: var(--one-ux-badge--font);
    font-weight: 700;
    letter-spacing: var(--one-ux-badge--letter-spacing);
  }

  .text-gradient .text {
    background: var(--one-ux-badge--text-color);
    background-clip: text;
    color: transparent;
  }

  .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icon-only {
    padding: var(--one-ux-badge--vertical-spacing);
    width: var(--one-ux-badge--height);
  }

  :host([weight='high']) .one-ux-element--root {
    --one-ux-badge--height: 24px;
    --one-ux-badge--vertical-spacing: var(--one-ux-spacing--smaller);
    --one-ux-badge--right-spacing: var(--one-ux-spacing--normal);
    --one-ux-badge--left-spacing: var(--one-ux-spacing--normal);
    --one-ux-badge--icon-size: var(--one-ux-size--icon-200);

    &.has-icon:not(.icon-only) {
      --one-ux-badge--left-spacing: var(--one-ux-spacing--small);
      --one-ux-badge--internal-spacing: var(--one-ux-spacing--small);
    }
  }

  :host([weight='low']) .one-ux-element--root {
    --one-ux-badge--height: 16px;
    --one-ux-badge--vertical-spacing: var(--one-ux-spacing--smallest);
    --one-ux-badge--right-spacing: var(--one-ux-spacing--small);
    --one-ux-badge--left-spacing: var(--one-ux-spacing--smaller);
    --one-ux-badge--font: var(--one-ux-font--mono-200);
    --one-ux-badge--letter-spacing: var(--one-ux-letter-spacing--mono-200);
    --one-ux-badge--internal-spacing: var(--one-ux-spacing--smaller);

    &.has-icon:not(.icon-only) {
      --one-ux-badge--left-spacing: var(--one-ux-spacing--smaller);
      --one-ux-badge--internal-spacing: var(--one-ux-spacing--smaller);
    }
  }

  ::slotted([one-ux-element='one-ux-icon']),
  [one-ux-element='one-ux-icon'] {
    font-size: var(--one-ux-badge--icon-size) !important;
  }

  .one-ux-element--root:not(.default) ::slotted([one-ux-element='one-ux-icon']),
  .one-ux-element--root.default ::slotted([one-ux-element='one-ux-icon']:not([color])) {
    --one-ux-icon-element--color: var(--one-ux-badge--icon-color) !important;
  }
`;