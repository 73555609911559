import { html, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { StyledFactory } from '../../mixins/Styled.js';
import { OneUxElement } from '../../OneUxElement.js';
import { style } from './style.js';
import { Weight } from '../../mixins/Weight.js';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { ref } from 'lit/directives/ref.js';
import { Implicit } from '../../mixins/Implicit.js';
import { badge, sets } from './badge-sets.js';
import { Label, hasInvalidLabel } from '../../mixins/Label.js';
import { log } from '../../utils/log.js';
import { HidableTooltip } from '../../mixins/HidableTooltip.js';
import { SlotController } from '../../controllers/SlotController.js';
import { getLangCode } from '../../utils/getLangCode.js';
import { Optional } from '../../types.js';
import { OneUxPaletteToken } from '../../generated/design-tokens.js';
import palette from '../../generated/json/palette/palette.json';
import { register as _registerElement } from "../one-ux-icon/register-element.js";
_registerElement("icon-66aaa3884dcd4ef864f13ce1bedfa4e4");
const Styled = StyledFactory(style);
const BaseClass = HidableTooltip(Label(Implicit(Weight(Styled(OneUxElement)))));
type setColorKeys = 'textColor' | 'iconColor' | 'backgroundColor';
export class OneUxBadgeElement extends BaseClass {
  static get elementType() {
    return 'one-ux-badge';
  }
  @property({
    type: String
  })
  public accessor set = 'default' as keyof typeof sets;
  @property({
    type: String
  })
  public accessor badge = 'default';
  @property({
    type: String,
    attribute: 'background-color'
  })
  public accessor backgroundColor: Optional<OneUxPaletteToken>;
  @property({
    type: String,
    attribute: 'text-color'
  })
  public accessor textColor: Optional<OneUxPaletteToken>;
  #slots: SlotController = new SlotController(this, {
    defaultSlot: true,
    slots: ['start']
  });
  render() {
    const badge = sets[this.set]?.[this.badge];
    if (!badge) {
      return;
    }
    const label = this.#getLabel(badge);
    if (hasInvalidLabel(this, label)) {
      return;
    }
    const textColor = this.#getColor(badge, 'textColor');
    const iconOnly = !badge.lockIcon && this.#slots.hasDefaultSlot() || !!badge.iconOnly;
    const hasIcon = !!badge.icon || !badge.lockIcon && (this.#slots.hasDefaultSlot('[one-ux-element="one-ux-icon"]') || this.#slots.hasNamedSlot('start', '[one-ux-element="one-ux-icon"]'));
    return html`<div
      class=${classMap({
      'one-ux-element--root': true,
      default: this.#isDefaultBadge,
      'icon-only': iconOnly,
      'has-icon': hasIcon,
      'text-gradient': !!textColor?.startsWith('linear-gradient')
    })}
      ${ref(($ref?: Element) => $ref && this.#handleTooltip($ref as HTMLElement, label!, iconOnly))}
      style=${styleMap({
      '--one-ux-badge--text-color': textColor,
      '--one-ux-badge--icon-color': this.#getColor(badge, 'iconColor'),
      '--one-ux-badge--background-color': this.#getColor(badge, 'backgroundColor')
    })}
    >
      ${this.#getContent(badge, label!)}
      <strong class="one-ux-accessibility--screen-reader">(${label})</strong>
    </div>`;
  }
  get #isDefaultBadge() {
    return this.set === 'default' && this.badge === 'default';
  }
  #handleTooltip($el: HTMLElement, label: string, iconOnly: boolean) {
    requestAnimationFrame(() => {
      if ($el) {
        const $text = $el.querySelector<HTMLElement>('.text');
        const hasTruncatedText = !!$text && $text.offsetWidth < $text.scrollWidth;
        if (hasTruncatedText || iconOnly) {
          $el?.setAttribute('one-ux-tooltip', label);
          $el?.toggleAttribute('one-ux-tooltip-fixed', iconOnly);
          $el?.toggleAttribute('one-ux-tooltip-custom-aria', true);
        } else {
          $el?.removeAttribute('one-ux-tooltip');
          $el?.removeAttribute('one-ux-tooltip-fixed');
          $el?.removeAttribute('one-ux-tooltip-custom-aria');
        }
      }
    });
  }
  #getColor(badge: badge, type: setColorKeys) {
    // When requesting the `iconColor` we use the override value of the `textColor` by default.
    // Overrides are done with the `color` property of the `one-ux-icon`.
    const prop = type === 'iconColor' ? 'textColor' : type;
    if (this.#isValidColor(this[prop])) {
      if (!this.#isDefaultBadge) {
        log.warning('Custom colors are only allowed with the default set and badge.');
      } else {
        if (type === 'backgroundColor' && this.implicit) {
          return `rgba(var(--one-ux-rgb--${this[prop]}), 0.12)`;
        }
        return `var(--one-ux-palette--${this[prop]})`;
      }
    }
    if (this.implicit) {
      const key = this.#getImplicitBadgeColorKey(type);
      const implicitColor = badge[key];
      if (implicitColor) {
        return implicitColor;
      }
    }
    const color = badge[type];
    if (color) {
      return color;
    }
    return null;
  }
  #getImplicitBadgeColorKey(type: setColorKeys) {
    switch (type) {
      case 'textColor':
        return 'implicitTextColor' as const;
      case 'iconColor':
        return 'implicitIconColor' as const;
      case 'backgroundColor':
        return 'implicitBackgroundColor' as const;
    }
    return type;
  }
  #getLabel(badge: badge) {
    if (this.label && !badge.lockLabel) {
      return this.label;
    }
    const locale = getLangCode(this);
    if (badge.label) {
      const label = badge.label(locale);
      if (label) {
        return label;
      }
    }
  }
  #getContent(badge: badge, label: string) {
    const icon = badge.icon;
    if (icon) {
      if (badge.iconOnly) {
        return this.#getIcon(badge);
      } else if (badge.lockIcon) {
        return [this.#getIcon(badge), this.#getText(label)];
      }
    }
    if (badge.lockIcon) {
      return this.#getText(label);
    }
    return html`<slot></slot> ${this.#slots.hasDefaultSlot() ? nothing : html`<slot name="start">${this.#getIcon(badge)}</slot> ${this.#getText(label)}`}`;
  }
  #getText(label: string) {
    return html`<strong class="text" aria-hidden="true">${label}</strong>`;
  }
  #getIcon(badge: badge) {
    const icon = badge.icon;
    if (icon) {
      return html`<icon-66aaa3884dcd4ef864f13ce1bedfa4e4 set=${icon.set} icon=${icon.icon}></icon-66aaa3884dcd4ef864f13ce1bedfa4e4>`;
    }
    return nothing;
  }
  #isValidColor(color: Optional<OneUxPaletteToken>) {
    return !!color && !!palette[color];
  }
}