import { PropertyValues, html, nothing } from 'lit';
import { property } from 'lit/decorators.js';
import { StyledFactory } from '../../mixins/Styled.js';
import { OneUxElement } from '../../OneUxElement.js';
import { style } from './style.js';
import { DialogController } from './DialogController.js';
import { classMap } from 'lit/directives/class-map.js';
import { FocusableFactory } from '../../mixins/Focusable.js';
import type { OneUxButtonElement } from '../one-ux-button/OneUxButtonElement.js';
import { keyCodes } from '../../utils.js';
import { flushAnimations } from '../../utils/animation-utils.js';
import { hideAnimation, showAnimation } from './animations.js';
import oneUxDuration from '../../generated/json/duration/duration.json';
import { getLanguage } from './language.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { TABBABLE_TARGETS_SELECTOR } from '../../utils/focusable.js';
import { SlotController } from '../../controllers/SlotController.js';
import { styleMap } from 'lit/directives/style-map.js';
import { Label } from '../../mixins/Label.js';
import { log } from '../../utils/log.js';
import { register as _registerElement } from "../one-ux-button/register-element.js";
import { register as _registerElement2 } from "../one-ux-icon/register-element.js";
import { register as _registerElement3 } from "../one-ux-scroll/register-element.js";
_registerElement3("scroll-c5a94b902330e1bfb7bc776fe4d29f75");
_registerElement2("icon-b349072c50496d8bb1673c1c2f978f7a");
_registerElement("button-5b329f3cc92a1e5ccfb8d89666efebb2");
const Styled = StyledFactory(style);
const Focusable = FocusableFactory(false);
const BaseClass = Label(Focusable(Styled(OneUxElement)));
export class OneUxDialogElement extends BaseClass {
  static get elementType() {
    return 'one-ux-dialog';
  }
  #controller = new DialogController(this);
  #slots: SlotController = new SlotController(this, {
    slots: ['header', 'header-start', 'subheader', 'header-end', 'custom-content', 'content', 'footer-start', 'footer-end']
  });
  @property({
    type: Boolean,
    reflect: true
  })
  accessor visible = false;
  @property({
    type: Boolean
  })
  accessor permanent = false;
  @property({
    type: String
  })
  accessor mode = 'modal' as 'none' | 'popover' | 'modal';

  /**
   * Closes the dialog.
   */
  close() {
    this.visible = false;
  }

  /**
   * Opens the dialog.
   */
  open() {
    this.visible = true;
  }

  // TODO: Will be replaced by a guardedRender in future major release to ensure that dialogs always have a label
  render() {
    const {
      translations,
      lang
    } = getLanguage(this);
    const isModalMode = this.mode !== 'none';
    return html`<div
        tabindex="-1"
        class=${classMap({
      backdrop: true,
      visible: isModalMode && this.#shouldShow
    })}
        @click=${() => {
      if (this.mode === 'popover') {
        this.#cancelableClose();
      }
    }}
        @focus=${() => {
      this.#focusOnStartOfContent();
    }}
      ></div>
      <div
        role="dialog"
        aria-label=${ifDefined(this.label ? this.label : undefined)}
        aria-labeledby=${ifDefined(!this.label ? 'headerText' : undefined)}
        aria-modal=${isModalMode}
        class=${classMap({
      dialog: true,
      visible: this.#shouldShow
    })}
        @keydown=${this.#handleKeydown}
      >
        <div tabindex="0" @focus=${() => this.#focusOn('end')}></div>
        <div tabindex="-1" class="one-ux-accessibility--screen-reader start-of-content" lang=${ifDefined(lang)}>
          ${translations.startOfContent}
        </div>
        <div
          class=${classMap({
      header: true,
      'has-header-start': this.#slots.hasNamedSlot('header-start'),
      'has-header-end': this.#slots.hasNamedSlot('header-end')
    })}
        >
          <slot name="header-start"></slot>
          <div class="header-text">
            <slot id="headerText" name="header">${this.label}</slot>
            <slot name="subheader"></slot>
          </div>
          <slot name="header-end"></slot>
          ${this.permanent ? nothing : html`
                <button-5b329f3cc92a1e5ccfb8d89666efebb2
                  compact
                  implicit
                  label=${translations.close}
                  lang=${ifDefined(lang)}
                  class="close"
                  hide-tooltip
                  @click=${this.#cancelableClose}
                >
                  <icon-b349072c50496d8bb1673c1c2f978f7a icon="close"></icon-b349072c50496d8bb1673c1c2f978f7a>
                </button-5b329f3cc92a1e5ccfb8d89666efebb2>
              `}
        </div>

        <slot name="custom-content">
          <scroll-c5a94b902330e1bfb7bc776fe4d29f75 class="content" gutter>
            <slot name="content"></slot>
          </scroll-c5a94b902330e1bfb7bc776fe4d29f75>
        </slot>

        <div
          class="footer"
          style=${styleMap({
      display: !this.#slots.hasNamedSlot('footer-start') && !this.#slots.hasNamedSlot('footer-end') ? 'none' : null
    })}
        >
          <div class="footer-start">
            <slot name="footer-start"></slot>
          </div>
          <div class="footer-end">
            <slot name="footer-end"></slot>
          </div>
        </div>

        <div tabindex="0" @focus=${() => this.#focusOn('beginning')}></div>
      </div>`;
  }
  protected async updated(changed: PropertyValues<this>) {
    if (changed.has('visible') && changed.get('visible') !== this.visible) {
      if (!this.#shouldShow) {
        return;
      }
      if (this.visible && !this.label) {
        log.deprecation('The "label" property should be provided for "<one-ux-dialog>". Will be enforced in a future major release.');
      }
      const $dialog = this.shadowRoot?.querySelector('.dialog') as HTMLElement;
      if ($dialog) {
        flushAnimations($dialog);
        if (this.visible) {
          this.#controller.startPositioning();
          $dialog.animate(showAnimation(), {
            duration: oneUxDuration[200]
          });
          this.#focusOnStartOfContent();
        } else {
          await $dialog.animate(hideAnimation(), {
            duration: oneUxDuration[200]
          }).finished;

          // Validation of the state is need as a long animation is awaited.
          // State could have changed programmatically whilst the animation was running.
          if (!this.visible) {
            this.#controller.stopPositioning();
            this.blur();
          }
        }
      }
    }
  }
  #cancelableClose = () => {
    if (!this.open || this.permanent) {
      return;
    }
    const close = new Event('close', {
      cancelable: true
    });
    if (this.dispatchEvent(close)) {
      this.close();
    }
  };
  #focusOn = (to: 'beginning' | 'end') => {
    const flattenFocusable = (children: Array<HTMLElement>) => children.flatMap($el => $el.matches(TABBABLE_TARGETS_SELECTOR) ? $el : Array.from($el.querySelectorAll(TABBABLE_TARGETS_SELECTOR)));
    const $closeButton = this.shadowRoot?.querySelector('.close') as OneUxButtonElement;
    const $focusable = [...this.#slots.getNamedSlotElements('header-start'), ...this.#slots.getNamedSlotElements('header-end'), ...(this.permanent ? [] : [$closeButton]), ...flattenFocusable(this.#slots.getNamedSlotElements('content')), ...this.#slots.getNamedSlotElements('footer-start'), ...this.#slots.getNamedSlotElements('footer-end')];
    const $target = $focusable.at(to === 'beginning' ? 0 : -1) as HTMLElement;
    if ($target) {
      $target.focus();
    } else {
      this.#focusOnStartOfContent();
    }
  };
  #focusOnStartOfContent() {
    const $startOfContent = this.shadowRoot?.querySelector('.start-of-content') as HTMLElement;
    $startOfContent?.focus();
  }
  #handleKeydown = (event: KeyboardEvent) => {
    if (event.code === keyCodes.ESCAPE) {
      this.#cancelableClose();
      event.stopPropagation();
    }
  };
  get #shouldShow() {
    return this.visible && (this.label || this.#slots.hasNamedSlot('header'));
  }
}