import { css, html } from 'lit';
import { OneUxElement } from '../../OneUxElement.js';
import { StyledFactory } from '../../mixins/Styled.js';
const Styled = StyledFactory(css`
  ::slotted(*) {
    display: none !important;
    visibility: hidden !important;
    position: fixed !important;
    pointer-events: none !important;
  }
`);
const BaseClass = Styled(OneUxElement);
/**
 * The text component is only used to position text in components that use slots.
 * It only allows text children and will hide all other elements.
 *  */
export class OneUxTextElement extends BaseClass {
  static get elementType() {
    return 'one-ux-text';
  }
  protected render() {
    return html`<slot></slot>`;
  }
}