import { PropertyValues, html, nothing } from 'lit';
import { state } from 'lit/decorators.js';
import { FocusableFactory } from '../../mixins/Focusable.js';
import { Implicit } from '../../mixins/Implicit.js';
import { Weight } from '../../mixins/Weight.js';
import { OneUxElement } from '../../OneUxElement.js';
import { classMap } from 'lit/directives/class-map.js';
import { Label } from '../../mixins/Label.js';
import { StyledFactory } from '../../mixins/Styled.js';
import { style } from './style.js';
import { Errors } from '../../mixins/Errors.js';
import { Compact } from '../../mixins/Compact.js';
import { ErrorsPopout } from '../../fragments/ErrorsPopout.js';
import { InternalElementStateChangedEvent } from '../../events/internal/InternalElementStateChangedEvent.js';
import { ILabelContext, labelContext } from '../../contexts/LabelContext.js';
import { provide } from '@lit/context';
import { Busy } from '../../mixins/Busy.js';
import { SlotController } from '../../controllers/SlotController.js';
import { ifDefined } from 'lit/directives/if-defined.js';
const MAX_HEIGHT_FOR_SINGLE_LINE_PER_WEIGHT = {
  low: 24,
  normal: 32,
  high: 46
};
const Styled = StyledFactory(style);
const Focusable = FocusableFactory(false);
const BaseClass = Busy(Errors(Label(Compact(Implicit(Weight(Styled(Focusable(OneUxElement))))))));
export class OneUxFieldElement extends BaseClass {
  static get elementType() {
    return 'one-ux-field';
  }
  @provide({
    context: labelContext
  })
  private _labelContext: ILabelContext = {
    label: this.label
  };
  @state()
  private accessor _multipleLines = false;
  @state()
  private accessor _childComponentDisabled!: boolean;
  @state()
  private accessor _childComponentRequired!: boolean;
  @state()
  private accessor _childComponentEmpty!: boolean;
  constructor() {
    super();
    this.addEventListener(InternalElementStateChangedEvent.eventName, (e: Event) => {
      const event = e as InternalElementStateChangedEvent;
      const {
        property,
        value
      } = event.detail;
      switch (property) {
        case 'disabled':
          this._childComponentDisabled = value as boolean;
          break;
        case 'required':
          this._childComponentRequired = value as boolean;
          break;
        case 'empty':
          this._childComponentEmpty = value as boolean;
          break;
      }
    });
  }
  protected willUpdate(changed: PropertyValues<this>): void {
    if (changed.has('label') && this._labelContext.label !== this.label) {
      this._labelContext = {
        label: this.label
      };
    }
  }
  protected guardedRender() {
    const hasMultipleDefaultSlots = !this.#slots.hasSingleDefaultSlot();
    return html`
      <div
        class="one-ux-element--root"
        role=${ifDefined(hasMultipleDefaultSlots ? 'group' : undefined)}
        aria-label=${ifDefined(hasMultipleDefaultSlots ? this._labelContext.label : undefined)}
      >
        ${this.compact ? nothing : html`
              <div class="label">
                <slot name="label-start"></slot>
                <span class="label-text">
                  ${this.label} ${this._childComponentRequired ? html`<span class="asterisk">*</span>` : nothing}
                </span>
                <slot name="label-end"></slot>
              </div>
            `}

        <div
          class=${classMap({
      'js-field': true,
      field: true,
      empty: this._childComponentEmpty,
      disabled: this._childComponentDisabled
    })}
        >
          ${this._multipleLines ? html`
                <div
                  class=${classMap({
      indicator: true,
      vertical: true,
      error: this.errors?.length,
      busy: this.busy,
      done: this.done
    })}
                ></div>
              ` : nothing}

          <slot></slot>

          <div
            class=${classMap({
      indicator: true,
      passive: this.implicit,
      error: !this._multipleLines && this.errors?.length,
      busy: !this._multipleLines && this.busy,
      done: !this._multipleLines && this.done
    })}
          ></div>
        </div>

        ${ErrorsPopout({
      reference: 'previous',
      errors: this.errors,
      hidden: this.hideErrors
    })}
      </div>
    `;
  }
  protected updated(): void {
    // Use fractional value as browser zoom gives inconsistant rounding of clientHeight and offsetHeight
    const fieldSize = this.shadowRoot!.querySelector('.js-field')!.getBoundingClientRect().height;
    this._multipleLines = fieldSize > MAX_HEIGHT_FOR_SINGLE_LINE_PER_WEIGHT[this.weight];
  }
  #slots: SlotController = new SlotController(this, {
    defaultSlot: true,
    slots: ['label-start', 'label-end']
  });
}