import { css } from 'lit';
export const style = css`
  .one-ux-element--root {
    display: flex;
    flex-direction: column;
  }

  .label {
    display: flex;
    flex-direction: row;
    gap: var(--one-ux-spacing--100);

    ::slotted([one-ux-element='one-ux-icon']) {
      color: var(--one-ux-palette--brand-neutral-600);
      font-size: var(--one-ux-size--icon-100);
      width: var(--one-ux-size--icon-100);
      height: var(--one-ux-size--icon-100);
    }
  }

  .label-text {
    font: var(--one-ux-font--label-400);
    letter-spacing: var(--one-ux-letter-spacing--label-400);
    color: var(--contextual-one-ux-field-element--label-color);
  }

  .asterisk {
    color: var(--one-ux-palette--red-500);
  }

  .field {
    --one-ux-field-element--border-width: var(--one-ux-border-width--500);

    flex: 1 1 auto;
    background: var(--one-ux-palette--gray-100);
    color: var(--one-ux-palette--brand-neutral-600);
    border: var(--one-ux-field-element--border-width) solid var(--one-ux-palette--brand-neutral-300);
    border-radius: var(--one-ux-radius--300);
    min-height: 32px; /* Fetch from somewhere? */
    cursor: default;

    font: var(--one-ux-font--body-300);
    letter-spacing: var(--one-ux-letter-spacing--body-300);

    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    transition-property: background, border-color, color;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);

    :host([weight='high']) & {
      min-height: 46px; /* Fetch from somewhere? */
    }

    :host([weight='low']) & {
      min-height: 24px; /* Fetch from somewhere? */
      font: var(--one-ux-font--body-200);
      letter-spacing: var(--one-ux-letter-spacing--body-200);
    }

    &.empty:not(:hover) {
      color: var(--one-ux-palette--brand-neutral-400);
    }

    &.disabled {
      background: var(--one-ux-palette--gray-150);
      color: var(--one-ux-palette--gray-400);
      border-color: var(--one-ux-palette--gray-300);
    }

    &:not(.disabled) {
      &:hover {
        border-color: var(--one-ux-palette--brand-neutral-400);
      }

      &:focus-within {
        border-color: var(--one-ux-palette--focus-400);

        :host([state-keyboard-focus]) & {
          background: var(--one-ux-palette--keyboard-focus-200);
          color: var(--one-ux-palette--brand-neutral-600);
          border-color: var(--one-ux-palette--keyboard-focus-400);
        }
      }
    }

    :host([implicit]) & {
      border-color: transparent;
      background: none;

      &.empty:not(:hover) {
        background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.03);
      }

      &.disabled {
        background: var(--one-ux-palette--gray-150);
        color: var(--one-ux-palette--gray-400);
      }

      &:not(.disabled) {
        &:hover {
          background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.05);
        }

        &:focus-within {
          border-color: var(--one-ux-palette--focus-400);
          background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.05);

          :host([state-keyboard-focus]) & {
            background: var(--one-ux-palette--keyboard-focus-200);
            color: var(--one-ux-palette--brand-neutral-600);
            border-color: var(--one-ux-palette--keyboard-focus-400);
          }
        }
      }
    }

    ::slotted(:is([one-ux-element='one-ux-input'], [one-ux-element='one-ux-textarea'])) {
      flex: 1 !important;
      height: 100% !important;
    }

    ::slotted([one-ux-element='one-ux-icon']) {
      font-size: var(--one-ux-size--icon-200);
    }
  }

  .indicator {
    --one-ux-field-element--indicator-indentation: var(--one-ux-spacing--small);
    --one-ux-field-element--indicator-gap: var(--one-ux-spacing--smaller);
    --one-ux-field-element--indicator-background: var(--one-ux-palette--brand-neutral-300);

    --one-ux-field-element--busy-duration: 1.5s; /* TODO: Missing token for duration */
    --one-ux-field-element--busy-easing: var(--one-ux-easing--default);

    --one-ux-field-element--done-duration: 1s; /* TODO: Missing token for duration */
    --one-ux-field-element--done-easing: var(--one-ux-easing--default);
    --one-ux-field-element--done-opacity-end: 0;
    --one-ux-field-element--done-color: var(--one-ux-palette--mint-400);

    position: absolute;
    pointer-events: none;
    background-color: inherit;
    opacity: 0;
    transition-property: opacity, background;
    transition-duration: var(--one-ux-duration--400);
    transition-timing-function: var(--one-ux-easing--default);

    border: solid transparent;
    border-inline-width: var(--one-ux-field-element--indicator-gap);
    border-block-width: 0;
    inset-inline: var(--one-ux-field-element--indicator-indentation);
    block-size: var(--one-ux-field-element--border-width);
    inset-block-end: calc(0px - var(--one-ux-field-element--border-width));

    &.vertical {
      writing-mode: vertical-rl;
    }

    &:before {
      content: '';
      display: block;
      block-size: 100%;
      background: var(--one-ux-field-element--indicator-background);
      border-radius: var(--one-ux-radius--100);
      transition-duration: inherit;
      transition-timing-function: inherit;
    }

    .field:not(.disabled, :focus-within):is(.empty, :hover) &.passive,
    &.error,
    &.busy {
      opacity: 1;
    }

    &.busy {
      --one-ux-field-element--indicator-background: var(--one-ux-palette--focus-400);

      animation:
        var(--one-ux-field-element--busy-duration) linear infinite busy-opacity,
        calc(2 * var(--one-ux-field-element--busy-duration)) var(--one-ux-field-element--busy-easing) infinite
          busy-edges;
    }

    &.done {
      animation: var(--one-ux-field-element--done-duration) var(--one-ux-field-element--done-easing) done;
    }

    &.error {
      --one-ux-field-element--indicator-background: var(--one-ux-palette--red-400);
      --one-ux-field-element--done-opacity-end: 1;
      --one-ux-field-element--done-color: var(--one-ux-palette--red-400);
    }
  }

  @keyframes busy-opacity {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes busy-edges {
    0% {
      inset-inline-start: var(--one-ux-field-element--indicator-indentation);
      inset-inline-end: calc(100% - var(--one-ux-field-element--indicator-indentation));
      border-inline-width: var(--one-ux-field-element--indicator-gap);
    }
    8% {
      inset-inline-start: var(--one-ux-field-element--indicator-indentation);
    }
    25% {
      border-inline-width: var(--one-ux-spacing--small);
    }
    35% {
      inset-inline-end: var(--one-ux-field-element--indicator-indentation);
    }
    50% {
      inset-inline-start: calc(100% - var(--one-ux-field-element--indicator-indentation));
      inset-inline-end: var(--one-ux-field-element--indicator-indentation);
      border-inline-width: var(--one-ux-field-element--indicator-gap);
    }
    58% {
      inset-inline-end: var(--one-ux-field-element--indicator-indentation);
    }
    75% {
      border-inline-width: var(--one-ux-spacing--small);
    }
    85% {
      inset-inline-start: var(--one-ux-field-element--indicator-indentation);
    }
    100% {
      inset-inline-start: var(--one-ux-field-element--indicator-indentation);
      inset-inline-end: calc(100% - var(--one-ux-field-element--indicator-indentation));
      border-inline-width: var(--one-ux-field-element--indicator-gap);
    }
  }

  @keyframes done {
    0% {
      --one-ux-field-element--indicator-background: var(--one-ux-field-element--done-color);
      inset-inline: calc(50% - var(--one-ux-field-element--indicator-indentation));
      opacity: 0;
      border-inline-width: var(--one-ux-spacing--large);
    }

    50% {
      --one-ux-field-element--indicator-background: var(--one-ux-field-element--done-color);
      inset-inline: var(--one-ux-field-element--indicator-indentation);
      opacity: 1;
    }

    90% {
      opacity: 1;
    }

    100% {
      opacity: var(--one-ux-field-element--done-opacity-end);
      border-inline-width: var(--one-ux-field-element--indicator-gap);
    }
  }

  :host(:not([disabled]):not(:is(:hover, :focus-within))) .errors-popout {
    visibility: hidden;
    pointer-events: none;
  }
`;